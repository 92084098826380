/** @jsx jsx */
import { jsx } from "theme-ui";

import { mapCraft } from "./mappings";
import { graphql } from "gatsby";

import DetailPageContainer from "@components/DetailPageContainer";

export default (props) => {
  const { entry } = mapCraft(props.data.craft);
  return (
    <DetailPageContainer
      entry={entry}
      pageType="author"
      location={props.location}
    />
  );
};

export const query = graphql`
  query AuthorQuery($id: Craft_QueryArgument) {
    craft {
      author: entry(id: [$id]) {
        id
        ...resourceAuthorFields
      }

      allEntries: entries {
        ... on Craft_report_report_Entry {
          title
          description
          typeHandle
          slug
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }
        ... on Craft_video_video_Entry {
          title
          embedVideo
          typeHandle
          slug
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }

        ... on Craft_statistic_statistic_Entry {
          title
          typeHandle
          slug
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }

        ... on Craft_report_report_Entry {
          title
          description
          typeHandle
          slug
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }
        ... on Craft_infographic_infographic_Entry {
          title
          description
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          img {
            title
            url
          }
          typeHandle
          slug
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }

        ... on Craft_academicArticle_academicArticle_Entry {
          title
          description
          typeHandle
          slug
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }

        ... on Craft_insight_insights_Entry {
          title
          typeHandle
          slug
          description
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }
        ... on Craft_tool_tool_Entry {
          title
          typeHandle
          slug
          description
          authors {
            ... on Craft_author_authors_Entry {
              id
              uri
              title
            }
          }
          populationGroupTags {
            __typename
            groupHandle
            slug
            title
          }
          industriesTags {
            groupHandle
            slug
            title
          }
          locationsTags {
            groupHandle
            slug
            title
          }
          socialImpactsTags {
            groupHandle
            slug
            title
          }
          insightsForTags {
            groupHandle
            slug
            title
          }
        }
      }
    }
  }
`;
