import { mapCraftRelatedEntry } from '@src/helpers/craft'

const mapEntry = (entry, related) => {
  const tags = [];

  if (entry.populationGroupTags && entry.populationGroupTags.length) {
    tags.push(...entry.populationGroupTags);
  }

  if (entry.industriesTags && entry.industriesTags.length) {
    tags.push(...entry.industriesTags);
  }
  if (entry.locationsTags && entry.locationsTags.length) {
    tags.push(...entry.locationsTags);
  }
  if (entry.socialImpactsTags && entry.socialImpactsTags.length) {
    tags.push(...entry.socialImpactsTags);
  }
  if (entry.insightsForTags && entry.insightsForTags.length) {
    tags.push(...entry.insightsForTags);
  }

  return {
    title: entry.title,
    description: entry.description,
    img:
      entry.img && entry.img.length
        ? {
            src: entry.img[0].url,
            alt: entry.img[0].title,
          }
        : null,
    type: entry.typeHandle,
    video: entry.embedVideo || "",
    attachments: entry.attachments,
    links: entry.externalLinks,
    authors: entry.authors || [],
    organisations: entry.organisations,
    related: related && related.length ? related.map(mapCraftRelatedEntry) : [],
    tags,
  };
};

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(
      craft.author,
      craft.allEntries
        .filter(
          ({ __typename }) =>
            __typename !== "Craft_author_authors_Entry" &&
            __typename !== "Craft_organisation_organisations_Entry"
        )
        .filter(({ authors }) => {
          if (
            authors &&
            authors.filter(({ id }) => id == craft.author.id).length
          ) {
            return true;
          } else {
            return false;
          }
        })
    ),
  };
};
